<template>
  <div>
    <Header />
    <div class="bg-mid-grey mt-5">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5 pb-5">
            <h1 class="page-header">Unit Cost</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light-grey">
      <div class="grid-container">
        <div class="grid-x grid-padding-x pt-5">
          <div class="cell small-12 medium-2 medium-offset-2">
            <label>Pack Size</label>
          </div>
          <div class="cell small-12 medium-2">
            <label>NHS List Price</label>
          </div>
          <div class="cell small-12 medium-2">
            <label>Discount (%)</label>
          </div>
          <div class="cell small-12 medium-2">
            <label>Local Price</label>
          </div>
          <div class="cell small-12 medium-2">
            <label>Net Price</label>
          </div>
        </div>
        <div v-for="(drug, index) in drugs" :key="`d_${index}`" class="grid-x grid-padding-x">
          <div class="cell small-12 medium-2">
            <button class="button expanded blue-active">
              <i class="plain" v-if="drug === 'byoviz'">Byooviz</i>
              <i class="plain" v-else>{{ drug }}</i>
              <sup v-if="drug !== 'biosimilars 2'" class="low-sup">7</sup>
            </button>
          </div>
          <div class="cell small-12 medium-2">
            <input class="fixed" disabled type="text" value="1">
          </div>
          <div class="cell small-12 medium-2">
            <input
                v-if="drug === 'byoviz'"
                class="fixed"
                disabled
                type="text"
                value="£523.45">
            <input
                v-if="drug === 'lucentis'"
                class="fixed"
                disabled type="text"
                value="£551.00">
            <input
                v-if="drug === 'biosimilars 2'"
                class="empty"
                type="text"
                v-model="biosimilarPrice">
          </div>
          <div class="cell small-12 medium-2">
            <number
              v-if="drug === 'byoviz'"
              class="empty"
              type="text"
              v-number="percent"
              v-model="byovizDiscount"></number>
            <number
              v-if="drug === 'lucentis'"
              class="empty"
              type="text"
              v-number="percent"
              v-model="lucentisDiscount"></number>
            <number
              v-if="drug === 'biosimilars 2'"
              class="empty"
              type="text"
              v-number="percent"
              v-model="biosimilarDiscount"></number>
          </div>
          <div class="cell small-12 medium-2">
            <number
              v-if="drug === 'byoviz'"
              v-number="number"
              class="empty"
              type="text"
              step="0.01"
              min="0"
              v-model="byovizLocalPrice"></number>
            <number
              v-if="drug === 'lucentis'"
              v-number="number"
              class="empty"
              type="text"
              step="0.01"
              min="0"
              v-model="lucentisLocalPrice"></number>
            <number
              v-if="drug === 'biosimilars 2'"
              v-number="number"
              class="empty"
              type="text"
              step="0.01"
              min="0"
              v-model="biosimilarLocalPrice"></number>
          </div>
          <div class="cell small-12 medium-2">
            <number
              v-if="drug === 'byoviz'"
              v-number="number"
              disabled
              type="text"
              class="fixed"
              v-model="byovizNetPrice"></number>
            <number
              v-if="drug === 'lucentis'"
              v-number="number"
              disabled
              type="text"
              class="fixed"
              v-model="lucentisNetPrice"></number>
            <number
              v-if="drug === 'biosimilars 2'"
              v-number="number"
              disabled
              type="text"
              class="fixed"
              v-model="biosimilarNetPrice"></number>
          </div>
        </div>
        <div class="grid-x grid-padding-x pt-50 pb-4">
          <div class="cell small-12 medium-2 medium-offset-10 text-right">
            <button @click="goToDosing" class="button">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'Costs',
  components: {
    Header,
  },
  data() {
    return {
      number: {
        separator: ',',
        precision: 2,
        masked: true,
        prefix: '£',
      },
      percent: {
        masked: true,
        suffix: '%',
      },
    };
  },
  computed: {
    biosimilarPrice: {
      get() { return this.$store.state.biosimilarPrice; },
      set(value) { this.$store.commit('setBiosimilarPrice', value); },
    },
    byovizDiscount: {
      get() { return this.$store.state.byovizDiscount; },
      set(value) { this.$store.commit('setByovizDiscount', value); },
    },
    lucentisDiscount: {
      get() { return this.$store.state.lucentisDiscount; },
      set(value) { this.$store.commit('setLucentisDiscount', value); },
    },
    biosimilarDiscount: {
      get() { return this.$store.state.biosimilarDiscount; },
      set(value) { this.$store.commit('setBiosimilarDiscount', value); },
    },
    byovizLocalPrice: {
      get() { return this.$store.state.byovizLocalPrice; },
      set(value) { this.$store.commit('setByovizLocalPrice', value); },
    },
    lucentisLocalPrice: {
      get() { return this.$store.state.lucentisLocalPrice; },
      set(value) { this.$store.commit('setLucentisLocalPrice', value); },
    },
    biosimilarLocalPrice: {
      get() { return this.$store.state.biosimilarLocalPrice; },
      set(value) { this.$store.commit('setBiosimilarLocalPrice', value); },
    },
    biosimilarNetPrice() {
      // eslint-disable-next-line
      let biosimilarPrice = this.biosimilarPrice;
      if (this.biosimilarDiscount > 0) {
        biosimilarPrice -= ((biosimilarPrice * this.biosimilarDiscount) / 100);
      }
      if (this.biosimilarLocalPrice > 0) biosimilarPrice = this.biosimilarLocalPrice;
      biosimilarPrice = Number.parseFloat(biosimilarPrice).toFixed(2);
      return `${biosimilarPrice}`;
    },
    byovizNetPrice() {
      let byovizPrice = 523.45;
      if (this.byovizDiscount > 0) {
        byovizPrice -= ((byovizPrice * this.byovizDiscount) / 100);
      }
      if (this.byovizLocalPrice > 0) byovizPrice = this.byovizLocalPrice;
      byovizPrice = Number.parseFloat(byovizPrice).toFixed(2);
      return `${byovizPrice}`;
    },
    drugs() {
      return this.$store.state.drugs;
    },
    hash() {
      return this.$store.state.hash;
    },
    lucentisNetPrice() {
      let lucentisPrice = 551.00;
      if (this.lucentisDiscount > 0) {
        lucentisPrice -= ((lucentisPrice * this.lucentisDiscount) / 100);
      }
      if (this.lucentisLocalPrice > 0) lucentisPrice = this.lucentisLocalPrice;
      lucentisPrice = Number.parseFloat(lucentisPrice).toFixed(2);
      return `${lucentisPrice}`;
    },
  },
  watch: {
    byovizLocalPrice() {
      this.$store.commit('setByovizDiscount', 0);
    },
    lucentisLocalPrice() {
      this.$store.commit('setLucentisDiscount', 0);
    },
    biosimilarLocalPrice() {
      this.$store.commit('setBiosimilarDiscount', 0);
    },
  },
  methods: {
    goToDosing() {
      this.$store.commit('setBiosimilarNetCost', this.biosimilarNetPrice);
      this.$store.commit('setByovizNetCost', this.byovizNetPrice);
      this.$store.commit('setLucentisNetCost', this.lucentisNetPrice);
      this.$router.push('/dosing');
    },
    sendPage() {
      this.$store.commit('setPage', 'costs');
    },
    checkHashExists() {
      if (this.hash.length === 0) {
        this.$router.push('/inactive-link');
      }
    },
  },
  mounted() {
    this.checkHashExists();
    this.sendPage();
  },
};
</script>
